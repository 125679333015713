import React, {useContext} from "react";
import {ReactComponent as LongArrow} from "assets/big_arrow.svg";
import Link from "components/link";

import { AppContext } from "appContext";

import "./nav-block-matrix.scss";

const NavBlockMatrix = ({navBlockMatrix}) => {

	const themeContext = useContext(AppContext);

	// On the get involved page specifically, every nav block color bar takes on the specific getInvolved page tint, 
	// whereas on other places in the App (ie. Homepage), the nav block color bar takes on page specific tints 
	const onGetInvolved = (themeContext.heroTheme === 'getInvolved');

 return (
    <div className="navMatrixCards"> 
		<div className="navMatrixGrid">
			{navBlockMatrix.map( ({id, cardDescription, pageLink}) => {
				return (
					<div key={id} className="navCardContainer"> 
						<Link className="navLinkContainer" {...pageLink}>
							<div className={`colorBar ${(pageLink.element && !onGetInvolved)  ? pageLink.element.uri: ''}`}></div>
							<div className="navCardContent">
								<h1>{pageLink.text}</h1>
								<p>{cardDescription}</p>
							</div>
							<LongArrow className="arrow" />
						</Link>
					</div>
				);
			})}
		</div>
	</div>
 );
}

export default NavBlockMatrix