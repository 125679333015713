import React, { useEffect, useState, useContext } from "react";
import "./navigation.scss";
import { useQuery } from "@apollo/react-hooks";
import query from "./query.js";
import Link from "components/link";
import useIsMobile from "helpers/useIsMobile";
import MenuIcon from "./menu-icon";
import { ReactComponent as SearchIcon } from "../../assets/search_icon.svg";
import { ReactComponent as WhiteSearchIcon } from "../../assets/white_search_icon.svg";
import { ReactComponent as CreBlueLogo } from '../../../src/assets/cre-logo.svg';
import { ReactComponent as CreWhiteLogo } from '../../../src/assets/cre-logo-white.svg';
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { ReactComponent as Minus } from "../../assets/minus.svg";
import { AppContext } from "appContext";

const breakpoint = 1250;

//	TODO
//	- Add Alert Banner above navigation
//
const Navigation = () => {
  const isMobile = useIsMobile(breakpoint);
  const navigationContext = useContext(AppContext);
  const [open, setOpen] = useState(false);

  const { loading, data, error } = useQuery(query);

  const openDropdown = (id, el) => {
    setOpen(id)
    if (el.hasAttribute("aria-haspopup")) {
      el.setAttribute("aria-expanded", "true")
    }
  }

  const closeDropdown = (el) => {
    setOpen(false)

    if (el.hasAttribute("aria-haspopup")) {
      el.setAttribute("aria-expanded", "false")
    }
  }

  if (loading) {
    return null;
  }

  if (error) {
    return "500";
  }

  return (
    <nav className={`${!isMobile && open ? "nav-open" : ""} ${navigationContext.isReport ? `reportNav` : navigationContext.hasHero ? `heroWrapper` :
      navigationContext.newsTheme ? `noHeroWrapper newsTheme` : `noHeroWrapper`}`}>
      <div className="navInner">
        <a href="/" className="logo" aria-label="Fountain House Logo">
          {navigationContext.hasHero ? <CreWhiteLogo alt="College Reentry Fountain House" /> : <CreBlueLogo alt="College Reentry Fountain House" />}
        </a>
        {isMobile ? <MobileNav items={data.nav.builder} open={open} setOpen={setOpen} /> :
          <ul className={`navLinks ${data.nav.builder.length > 6 ? "widen" : ""}`}>
            {data.nav.builder.map(
              ({ id, linkTo, children }) => {
                if (linkTo.text === 'Search') {
                  return (
                    <li key={id} className={`listItem`}>
                      {linkTo && linkTo.element ? <Link navLink={true} className={`navItem searchIcon`} {...linkTo} aria-label="Search">
                        {navigationContext.hasHero ? <WhiteSearchIcon alt="Search Icon" /> : <SearchIcon alt="Search Icon" />}
                      </Link> : ""}
                    </li>
                  );
                } else if (linkTo.text === 'Donate') {
                  return (
                    <li key={id} className={`listItem`}>
                      {linkTo && linkTo.element ? <Link navLink={true} className={`navItem donateBtn`} {...linkTo} /> : ""}
                    </li>
                  );
                } else {
                  return children.length ? <li
                    key={id}
                    className={`listItem has-children ${open === id ? "active" : ""}`}
                    onMouseOver={(e) => { openDropdown(id, e.target) }}
                    onMouseOut={(e) => { closeDropdown(e.target) }}
                    onKeyUp={(e) => { openDropdown(id, e.target) }}
                    onBlur={(e) => { closeDropdown(e.target) }}
                  >
                    {linkTo && linkTo.element ? <Link
                      navLink={true}
                      className={`navItem`}
                      aria-haspopup="true"
                      aria-expanded="false"
                      {...linkTo}
                    /> : <button className={`navItem`}
                      aria-haspopup="true"
                      aria-expanded="false">{linkTo.text}</button>
                    }
                    <div className={`dropdown ${open && open === id ? "open" : "closed"}`}>
                      <ul className={`dropdown-wrapper ${children.length > 4 ? "wider" : ""}`} id={id}>
                        {children.map(link => <li key={link.id} className={`dropdown-item`}>
                          {linkTo ? <Link navLink={true} className={`dropdown-navItem`} {...link.linkTo} /> : ""}
                        </li>)}
                      </ul>
                    </div>
                  </li> :
                    <li key={id} className={`listItem`}>
                      {linkTo && linkTo.element ? <Link
                        navLink={true}
                        className={`navItem`}
                        {...linkTo}
                      /> : ""}
                    </li>
                }
              }
            )}
          </ul>
        }
      </div>
    </nav>
  );
};

const MobileNav = ({
  items,
  open,
  setOpen
}) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    document.body.style.overflow = active ? "hidden" : "";
    return () => {
      document.body.style.overflow = "";
    };
  }, [active]);
  const closeMenu = () => setActive(false);
  return (
    <div className={`mobileNav ${active ? "active" : ""}`}>
      <div className="mobileNav-toggleph">
        <button className="mobileNavToggle" onClick={() => setActive(!active)} aria-label="Mobile Nav Menu Toggle Button">
          <MenuIcon active={active} />
        </button>
      </div>
      {active && items && (
        <MobileNavItems items={items} closeMenu={closeMenu} open={open} setOpen={setOpen} />
      )}
    </div>
  );
};

const MobileNavItems = ({
  items,
  closeMenu,
  open,
  setOpen
}) => {
  const navigationContext = useContext(AppContext);
  const [height, setHeight] = useState(0);

  const openDropdown = (id, el) => {
    let grow = id !== open && el
    let elHeight = el.nextElementSibling.firstElementChild

    setHeight(grow ? elHeight.getBoundingClientRect().height + 16 : 0)
    setOpen(id === open ? false : id)
  }

  return (
    <div className={"mobileNav-wrapper"}>
      <ul className="mobileNav-links">
        {items.map(({ linkTo, id, children, ...rest }) => {
          if (linkTo.text === 'Search') {
            return (
              <li key={id}>
                {linkTo && linkTo.element ? <Link className={`navItem searchIcon`} onClick={closeMenu} {...linkTo} aria-label="Search">
                  {navigationContext.hasHero ? <WhiteSearchIcon alt="Search Icon" /> : <SearchIcon alt="Search Icon" />}
                </Link> : ""}
              </li>
            );
          } else if (linkTo.text === "Donate") {
            return (
              <li key={id}>
                {linkTo && linkTo.element ? <Link className={`navItem donateBtn`} onClick={closeMenu} {...linkTo} /> : ""}
              </li>
            );
          } else {
            return children.length ?
              <li key={id} className={`mobileNav-accordion`}>
                <button className={`navItem mobileNav-label`} onClick={(e) => { openDropdown(id, e.currentTarget) }}>
                  <span className={`navItem`}>{linkTo.text}</span>
                  {open && open === id ? <figure className="accordionSvg"><Minus /></figure> : <figure className="accordionSvg"><Plus /></figure>}
                </button>
                <div className={`mobileNav-dropdown ${open && open === id ? "open" : "closed"}`} style={open && open === id ? { height } : {}}>
                  <ul className={`dropdown-wrapper ${children.length > 4 ? "wider" : ""}`} id={id}>
                    <li className={`mobileNav-dropdown-item`}>
                      {linkTo && linkTo.element ? <Link className={`mobileNav-dropdown-navItem navItem`} {...linkTo} /> : ""}
                    </li>
                    {children.map(link => <li key={link.id} className={`mobileNav-dropdown-item`}>
                      {linkTo && linkTo.url ? <Link className={`mobileNav-dropdown-navItem navItem`} onClick={closeMenu} {...link.linkTo} /> : ""}
                    </li>)}
                  </ul>
                </div>
              </li> : (
                <li key={id}>
                  {linkTo && linkTo.element ? <Link className={`navItem`} onClick={closeMenu} {...linkTo} /> : ""}
                </li>
              );
          }

        })}
      </ul>
    </div>
  );
};

export default Navigation
