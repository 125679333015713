import { idtype, pageLink } from "querypieces";

export default `
...on pageBuilder_navBlockMatrix_BlockType {
	${idtype}
	navBlockMatrix {
	  ...on navBlockMatrix_navBlock_BlockType {
		id
		cardDescription
		${pageLink}
	  }
	}
  }
`
