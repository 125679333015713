import React from "react"
import Button from "components/button";
import "./contact-block.scss";


const ContactBlock = ({headline, body, contactName, emailAddress, phoneNumber, downloadCta}) => {

    return (
        <div className="contactBlock">
            {headline ? <h3 className="contactHeadline" >{headline}</h3> : null}
            {body ? <p className="contactBody">{body}</p> : null}
            
            {contactName ? <p className="contactName">{contactName}</p> : null}
            { phoneNumber && phoneNumber.url ? <a className="contactPhone" href={phoneNumber.url}>{phoneNumber.text}</a> : null}
            { emailAddress && emailAddress.url ? <a className="contactEmail" href={emailAddress.url}>{emailAddress.text}</a> : null}
            {downloadCta && downloadCta.url ? 
                <Button
                    className="downloadCta"
                    theme="blue"
                    primary={false}
                    {...downloadCta}
                >
                </Button>
            : null}
        
        </div>  
    );
};

export default ContactBlock