import React from "react"
import styles from "./richText.module.scss"

const RichText = ({ richText, textColor, className }) => {
    const textColorStyle = textColor ? textColor : 'blue';
    const propClass = className ? className : '';
    
    return (
        textColorStyle === "white" ?
            <div dangerouslySetInnerHTML={{ __html: richText }} className={`${styles.richTextField} ${styles.whiteText} ${propClass}`}></div>
        : 
            <div dangerouslySetInnerHTML={{ __html: richText }} className={`${styles.richTextField} ${styles.blueText} ${propClass}`}></div>
    );
}

export default RichText