import React, {useState} from "react"
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../assets/white_arrow.svg";

import "./leadership-team.scss"

const LeadershipTeam = ( {heading, plainText, body, children} ) => {
    
    const [activeTab, setActiveTab] = useState('Leadership');

    return (

        <div className="leadershipWrapper" id="leadershipTeam" >
            <div className="leadershipInnerWrapper">
                <h1 className="leadershipHeader">{heading}</h1>
                <p className="leadershipBody">{body}</p>
                <div className="filterHeader">
                    <p className="filterTitle">{plainText}</p>
                    <ul className="tabTitles">
                        {children.map( ({id, teamType}) => 
                            <li 
                                key={id}
                                role="button"
                                tabIndex="0"
                                aria-pressed={teamType === activeTab ? true : false}
                                className={teamType === activeTab ? `active tabTitle` : `tabTitle` } 
                                onClick={(e) => {
                                    setActiveTab(teamType);
                                    e && e.target && e.target.blur();
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        setActiveTab(teamType);
                                    }
                                }}
                            >                              
                                {teamType}
                            </li>
                            
                        )}
                    </ul>

                    <div className="tabContent">
                        {children.map( ({teamType, leadershipLink}) => {
                            if (activeTab === teamType) {
                                return (
                                    leadershipLink.map ( ({id, bioPageSwitch, fullName, positionTitle, uri}) => 
                                        bioPageSwitch ? 
                                            <Link key={id} to={uri}>
                                                <div className="leadershipTile">
                                                    <div className="tileTop" >
                                                        <h3>{fullName}</h3>
                                                        <figure className="tileArrowWrapper" >
                                                            <Arrow className="tileArrow"/>
                                                        </figure>
                                                    </div>
                                                    <p>{positionTitle}</p>
                                                </div>
                                            </Link>
                                        : 
                                        <div key={id} className="leadershipTile">
                                            <div className="tileTop" >
                                                <h3>{fullName}</h3>
                                            </div>
                                            <p>{positionTitle}</p>
                                        </div>
                                    )
                                );
                            } else {
                                return null;
                            }
                        }
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}



export default LeadershipTeam