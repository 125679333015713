import React from "react";
import Button from "components/button";
import RichText from "components/richText";

import "./header.scss";

const Header = ({breadcrumb, heading, introBody, richText, cta, isReport=false}) => {

    return (
        <div className={`headerWrapper ${isReport ? 'specialReportHeader' : ''}`}>
            { breadcrumb ? <p className="headerBreadcrumb">{breadcrumb}</p> : "" }
            { heading ? <h1 className="headerHeading">{heading}</h1> : "" }  
            { introBody ? <p className="headerIntroBody">{introBody}</p> : "" }
            { richText ? 
                <div className="headerBody">
                    <RichText 
                        richText={richText}
                        textColor="blue"
                    />
                </div> 
            : null}
            {(cta.url || cta.element) ? 
                    <Button  
                        theme="blue" 
                        primary={!isReport}
                        className="headerCTA"
                        {...cta}
                    >
                    </Button> : null}
        </div>
    ); 
}

export default Header