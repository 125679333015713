import React from "react";
import "./email-success.scss";


const EmailSuccess = () => {

    return (
        <div className="emailSuccess">
            <p>Thank you for subscribing</p>
        </div>
    );
    
}

export default EmailSuccess