import { idtype, featurePostImage, featurePostLinkTo } from "querypieces";

export default `
...on pageBuilder_featurePost_BlockType {
	${idtype}
	id
	altText
	plainText
	themePicker
	${featurePostImage}
	${featurePostLinkTo}
}
`