import React from "react";
import { Link as RouterLink, NavLink } from "react-router-dom";
import ReactGA from "react-ga";

const Link = ({ url, navLink, text, element, children, __typename, type, ...rest }) => {

  if (navLink) {
    return element && element.uri ? (
      <NavLink
        to={`/${element.uri === "__home__" ? "" : element.uri}`}
        activeClassName={"active"}
        {...rest}
      >
        {children || text}
      </NavLink>
    ) : <NavLink activeClassName={"active"} to={url || ""} {...rest}>{text}</NavLink>;
  }

  switch (type) {
    case "entry":
      return element && (
        <RouterLink
          to={`/${element.uri === "__home__" ? "" : element.uri}`}
          {...rest}
        >
          {children || text}
        </RouterLink>
      );

    case "asset":
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          {...rest}
        // onClick={() => {
        //   ReactGA.event({
        //     category: "Download",
        //     action: "Download",
        //     label: (element && element.title) || url,
        //   });
        // }}
        >
          {children || text}
        </a>
      );

    default:
      return url ? (
        <ReactGA.OutboundLink
          to={url}
          target="_blank"
          eventLabel={url}
          {...rest}
        >
          {children || text}
        </ReactGA.OutboundLink>
      ) : null;
  }
};

export default Link;
