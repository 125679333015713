import React, {useState, useRef, useLayoutEffect} from "react";
import {
  ComposableMap,
  Geographies,
  Geography
} from "react-simple-maps"; 
import ReactTooltip from "react-tooltip";
import styles from "./parterSiteMap.module.scss";


const Map = ({ markers, isMobile }) => {
  const jsonData = require('./mapData.json');
  const [content, setContent] = useState({heading: "", richtext: ""});
  const toolTipRef = useRef([]);

  useLayoutEffect(() => {
    ReactTooltip.rebuild()
  });

  const markerStates = markers.map(element => {
    return element.location.parts.state;
  });
  
  return (
    <React.Fragment>
      <ReactTooltip 
        effect="solid" 
        event="focus mouseenter"
        role="tooltip" 
        id="mapTooltip"
        className={styles.tooltip}
        scrollHide={false}
        delayHide={200}
        clickable={true}
      >
        <div className={styles.tooltipContent}>
          <h3 className={styles.tooltipHeading}>{content.heading}</h3>
          <div className={styles.tooltipRichText} dangerouslySetInnerHTML={{ __html: content.richtext }}></div> 
        </div>
        
      </ReactTooltip>
      <ComposableMap 
        projection="geoAlbersUsa"
        role="img"
        aria-labelledby="mapDesc"
      >
        <desc id="mapDesc">A map of the United States of America with each state outlined. States that have a Fountain House partner site are highlighted in red.</desc>
        <Geographies geography={jsonData} onClick={(event)=>event.preventDefault}>
        {({ geographies }) => 
          geographies.map( (geo, i) => {
            return (markerStates.includes(geo.properties.name) ?
               
              // States w/ program locations
              <g
                ref={el => toolTipRef.current[i] = el}        
                data-tip=''
                data-for="mapTooltip"
                key={geo.rsmKey}
              > 
                <Geography
                  stroke="#172663"
                  geography={geo}
                  fill={"#FF6666"}
                  tabIndex="-1"
                  data-for="mapTooltip"
                  aria-describedby="mapTooltip"
                  pointerEvents={isMobile ? "none" : ''}
                  
                  onMouseEnter={() => {
                    let matchingState = markers.find(element => element.location.parts.state === geo.properties.name);
                    setContent({heading: matchingState.heading, richtext: matchingState.richText});
                  }}
                  onMouseLeave={() => {
                    toolTipRef.current[i].setAttribute('currentItem', false);
                    ReactTooltip.hide();
                  }}
                  onFocus={() => {
                    let matchingState = markers.find(element => element.location.parts.state === geo.properties.name);
                    setContent({heading: matchingState.heading, richtext: matchingState.richText});
                    ReactTooltip.show();
                  }}
                  onBlur={() => {
                    ReactTooltip.hide();
                  }} 
                />
              </g>
            :
            <Geography
              key={geo.rsmKey}
              stroke="#172663"
              geography={geo}
              fill={"#FFF"}
              pointerEvents={"none"}
            />
            )}
          )
        }
        </Geographies>
      </ComposableMap>
    </React.Fragment>
  );
};

export default Map;