import { idtype } from "querypieces";

export default `
...on pageBuilder_outcomesMatrix_BlockType {
	${idtype}
	heading
	outcomesMatrix {
		... on outcomesMatrix_outcomeBlock_BlockType {
		  id
		  altText
		  subheader
		  bodyCopy
		  image {
			id
			extension
			title
			url
			img
		  }
		}
	  }
}
`