import React from "react"
import ContactBlock from "blocks/contact-block.js";
import RichText from "components/richText";

import "./body-text-and-contact-blocks.scss";

const BodyTextAndContactBlocks = ({ simpleRichTextBody, subheader, contactBlock }) => {
    
    return (

        <div className="bodyContactWrapper"> 
            { subheader ? <h3 className="bodyContactSubheader">{subheader}</h3> : null} 
            
            { simpleRichTextBody ? 
                <div className="bodyContactSimpleBody">
                    <RichText 
                        richText={simpleRichTextBody}
                        textColor="white"
                    />
                </div> 
            : null}
            
            { contactBlock.length > 0 ?
                <div className="contactBlocksWrapper">
                    {contactBlock.map( ({id, ...rest}) => {
                        
                        return (
                            <ContactBlock key={id} {...rest}/>
                        );
                    }  
                    )}
                </div>
            : null}
        </div>
    );
}

export default BodyTextAndContactBlocks