export const idtype = `id
typeHandle
slug`;

const optimized = `
  src
  srcset
  srcsetWebp
  placeholderImage
  width: originalImageWidth
  height: originalImageHeight
`;

export const link = `
  url
  text
  type
  element {
    id
    uri
    title
  }
`;

export const blogPostLink = `
  url
  text
  type
  element {
    id
    uri
    title
    ... on blog_blog_Entry {
      id
      articleType
      relatedArticleImage {
        ...on images_Asset {
          optimized: optimizedRelatedArticleImage {
            ${optimized}
          }
        }
      }
      blogPostHeaderMatrix {
        ... on blogPostHeaderMatrix_blogPostHeading_BlockType {
          id
          date
          authors
          header
          publisher
        }
      }
    }
  }
`;



export const featurePostLinkTo = `
  linkTo {
    ${blogPostLink}
  }
`;

export const relatedArticleLink = ` 
  articleLink {
    ${blogPostLink}
  }
`;

export const linkTo = `
linkTo {
  ${link}
}
`;

export const pageLink = `
pageLink {
  ${link}
}
`;

export const blogImage = `
  blogImage {
    extension
    title
    url
    ...on images_Asset {
      optimized: optimizedInlineImage {
        ${optimized}
      }
    }
  }  
`;

export const featurePostImage = `
  featurePostImage {
    title
    ...on images_Asset {
      optimized: optimizedFeaturePostImage {
        ${optimized}
      }
    }
  }
`;

export const inlineImage = `
  inlineImage {
    extension
    title
    url
    ...on images_Asset {
      optimized: optimizedInlineImage {
        ${optimized}
      }
    }
  }
`;

export const fullPageImage = `
  image {
    extension
    title
    url
    ...on images_Asset {
      optimized: optimizedFullPageImage {
        ${optimized}
      }
    }
  }
`;

export const heroImg = `
  heroImage {
    title
    kind
    url
    ...on images_Asset {
      optimized: optimizedHeroImage {
        ${optimized}
      }
    }
  }
`;

export const halfPageImage = `
  halfPageImage {
    extension
    title
    url
    ...on images_Asset {
     optimized: optimizedHalfPageImage {
       ${optimized}
     }
    }
  }
`;

export const relatedArticleImage = `
  relatedArticleImage {
    title
    ...on images_Asset {
      optimized: optimizedRelatedArticleImage {
        ${optimized}
      }
    }
  }
`;

export const image = `
  image {
    ...on images_Asset {
     optimized: optimizedAny {
       ${optimized}
     }
    }
  }
`;

export const relatedArticleCard = `
id
... on blog_blog_Entry {
  id
  altText
  articleType
  ${relatedArticleImage}
  blogPostHeaderMatrix {
    ... on blogPostHeaderMatrix_blogPostHeading_BlockType {
      id
      header
      publisher
      date
      authors
    }
  }
  uri
}
... on blog_externalPost_Entry {
  id
  altText
  articleType
  externalUrl {
      url
  }
  blogPostHeaderMatrix {
    ... on blogPostHeaderMatrix_blogPostHeading_BlockType {
      id
      date
      authors
      header
      publisher
    }
  }
  ${relatedArticleImage}
}   
`;

export const location = `
  ${idtype}
  title
  location {
    address
    lat
    lng
    parts {
      address
      city
      number
      postcode
      state
    }
  }
`;

export const featureReportLinkTo = `
linkTo {
  url
  text
  type
  element {
    id
    uri
    title
    ... on reports_reports_Entry {
      id
      title
      ${heroImg}
      heroHeading: plainText
      breadcrumb
      heading
      introBody
    }
  }
}
`

export const featurePageLinkTo = `
  linkTo {
    url
    text
    type
    element {
      id
      uri
      title
      ...on pages_page_Entry {
        typeHandle
        pageBuilder {
          ...on pageBuilder_hero_BlockType {
            ${idtype}
            altText
            themePicker
            ${heroImg}
          }
          ...on pageBuilder_header_BlockType {
            ${idtype}
            breadcrumb
            heading
            introBody
            richText
            cta {
              title
              text
              type 
              url 
              element {
                uri
              }
            }
          }
        }
      }
    }
  }
`;