import React from "react"
import styles from "./not-found.module.scss"

const NotFound = () => {
    return (
        <div className={styles.notFoundPage}>
            <h1>Sorry! Page Not Found.</h1>
        </div>
    );
}

export default NotFound