import { gql } from "apollo-boost";
import { location } from "querypieces";


export default gql `
  query {
    entries(section: "careResponderLocations") {
      id
      ... on careResponderLocations_careResponderLocations_Entry {
        id
        ${location}
        heading 
        richText
      }
    }
  }
`;