import React from "react";
import Image from "components/image";
import RichText from "components/richText";

import styles from "./outcomes-matrix.module.scss"

const OutcomesMatrix = ({heading, outcomesMatrix}) => {

    return (
        <div className={styles.outcomeSectionWrapper}>
            <div className={styles.outcomeSectionInnerWrapper}>

            <h1>{heading}</h1>

            <div className={styles.outcomeBlockWrapper}>

                {outcomesMatrix.map( ({id, altText, bodyCopy, subheader, image}) => {
                    
                    const {url, extension, title} = image[0];

                    return (
                        <div key={id} className={styles.outcomeBlock}>
                            <Image title={title} alt={altText} extension={extension} url={url}/>
                            <div className={styles.textBlock}>
                                <h3>{subheader}</h3>
                                <div className={styles.richTextWrapper} >
                                    <RichText 
                                        richText={bodyCopy}
                                        textColor="blue"
                                    />
                                </div>
                            </div>
                        </div>     
                    );
                
                })}

            </div>

            </div>
        </div>
    );
}

export default OutcomesMatrix