import { gql } from "apollo-boost";
import { idtype, linkTo } from "querypieces";

export default gql`
query {
  nav: globalSet(handle:"navigation") {
    ...on navigation_GlobalSet {
      builder: navigation {
        ...on navigation_navitem_BlockType {
          ${idtype}
          ${linkTo}
          children {
            ...on navigation_navitem_BlockType {
              ${idtype}
              ${linkTo}
            }
          }
        }
      }
    }
  }
}
`;