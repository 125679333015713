import React from "react"
import Image from "components/image";
import Button from "components/button";
import RichText from "components/richText";

import "blocks/theme-picker.scss";
import styles from "./full-page-photo-matrix.module.scss";
 
const FullPagePhotoMatrix = ({themePicker, fullPagePhotoMatrix}) => {

    return (
        <div className={`${themePicker} ${styles.fullPagePhotoWrapper}`}>
            {fullPagePhotoMatrix.map ( (elem) => {
                if (elem.__typename === "fullPagePhotoMatrix_header_BlockType") {
                    return (
                        <h1 key={elem.id} className={styles.header}>{elem.header}</h1>
                    );
                } else if (elem.__typename === "fullPagePhotoMatrix_fullPagePhoto_BlockType") {
                    if (elem.photoBorderSwitch) {    
                        return (
                            <figure key={elem.id} className={styles.imageBorder}>
                                { elem.image[0].extension === "svg" ? 
                                    <Image extension={elem.image[0].extension} url={elem.image[0].url} alt={elem.altText} title={elem.image[0].title}/>
                                    : <Image {...elem.image[0].optimized} title={elem.image[0].title} alt={elem.altText}/>
                                }
                            </figure>
                        );
                    } else {
                        return (
                            <figure key={elem.id} className={styles.noImageBorder}>
                                { elem.image[0].extension === "svg" ? 
                                    <Image extension={elem.image[0].extension} url={elem.image[0].url} alt={elem.altText} title={elem.image[0].title}/>
                                    : <Image {...elem.image[0].optimized} alt={elem.altText} title={elem.image[0].title}/>
                                }
                            </figure>
                        );
                    }
                } else if (elem.__typename === "fullPagePhotoMatrix_introBody_BlockType"){
                    return (
                        <p key={elem.id} className={styles.introBody}> {elem.introBody} </p>
                    );
                } else if (elem.__typename === "fullPagePhotoMatrix_subheader_BlockType"){
                    return ( 
                        <h3 key={elem.id} className={styles.fullPageSubheader}> {elem.subheader} </h3>
                    );
                } else if (elem.__typename === "fullPagePhotoMatrix_body_BlockType"){
                    return (
                        <div key={elem.id} className={styles.body}>
                            <RichText 
                                richText={elem.body}
                                textColor="blue"
                            />
                        </div>
                    );

                } else if (elem.__typename === "fullPagePhotoMatrix_cta_BlockType"){
                    return (
                        <div key={elem.id} className={styles.cta}>
                            <Button 
                                primary={true}
                                theme="blue"
                                {...elem.cta}
                            ></Button>
                        </div>
                    );
                }
                return (null);
            })}

        </div>
    
    );
}

export default FullPagePhotoMatrix