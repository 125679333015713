import React, { useEffect, useContext } from "react"
import styles from "./blog.module.scss"
import fetch from "../../fetch";
import query from "./query";
import Loading from "components/loading";
import Error from "components/error";
import NotFound from "components/not-found";
import Image from "components/image";
import RichText from "components/richText";
import Link from "components/link";
import Button from "components/button";
import Seo from "components/seo";

import { AppContext } from "appContext";

const Blog = ({ match }) => {
  const uri = match.url.replace("/", "");
  const { data, loading, error } = fetch(query, uri);
  const navigationContext = useContext(AppContext);

  useEffect(() => {
    navigationContext.setHasHero(false);
    navigationContext.setNewsTheme(true);
    navigationContext.setIsReport(false);
  }, [data, navigationContext]);

  if (loading) {
    return <Loading />;
  }

  if (error && !data) {
    return <Error />;
  }

  if (!data.entry) {
    return <NotFound />;
  }

  const { articleType, blogBuilderMatrix } = data.entry;
  const { header, date, publisher, authors } = data.entry.blogPostHeaderMatrix[0];

  const linkElement = {
    element: {
      uri: `blog?filter=${articleType}`
    }
  };

  return (
    <React.Fragment>
      <Seo seomaticData={data.seomatic} />
      <div className={styles.contentWrapper}>
        <Link
          type="entry"
          className={styles.typeHandle}
          {...linkElement}
        >
          {articleType}
        </Link>
        <h1 className={styles.articleHeader}>{header}</h1>
        <div className={styles.details}>
          <p className={styles.date}>{date}</p>
          <p className={styles.publisher}>{publisher}</p>
        </div>
        {authors ?
          <div className={styles.authors}>
            <p> {authors} </p>
          </div>
          : null}
        <div className={styles.articleContentWrapper}>
          {blogBuilderMatrix.map((elem) => {

            if (elem.__typename === 'blogBuilderMatrix_blogRichText_BlockType') {
              return (
                <div key={elem.id} className={styles.articleContent}>
                  <RichText
                    richText={elem.richText}
                    textColor="blue"
                  />
                </div>
              );
            } else if (elem.__typename === "blogBuilderMatrix_primaryCta_BlockType") {
              return (
                (elem.primaryCta.url || elem.primaryCta.element) ?
                  <Button
                    theme="blue"
                    primary={true}
                    className={styles.blogBtn}
                    {...elem.primaryCta}
                  >
                  </Button> : null
              );
            } else {
              return (
                <React.Fragment key={elem.id}>
                  {elem.blogImage.length > 0 ?
                    <figure className={styles.imageBorder}>
                      <Image className={styles.blogImage} alt={elem.altText} title={elem.blogImage[0].title} {...elem.blogImage[0].optimized} />
                    </figure>
                    : null
                  }
                  {elem.caption ? <p className={styles.caption}> {elem.caption} </p> : ''}
                </React.Fragment>
              );
            }
          })
          }
        </div>
      </div>
    </React.Fragment>
  );





}

export default Blog