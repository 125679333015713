import { useQuery } from "@apollo/react-hooks";

export default (query, searchVariable, offset, limit) => {

      const fetch = query;

      const { loading, error, data, fetchMore } = useQuery(fetch, {
            variables: {
                  search: searchVariable,
                  offset: offset,
                  limit: limit
            },
            notifyOnNetworkStatusChange: true
      });

      return {
            loading,
            error,
            data,
            fetchMore
      };
};