import React from "react"
import Link from "components/link"
import styles from "./sitemap.module.scss"

const Sitemap = ({ results, data }) => {
  if (!data) return null

  return <div className={styles.element}>
    <h2 className={styles.header}>
      {results}
    </h2>
    <div className={styles.content}>

      {data.map(list => {
        return <ul className={styles.list} key={list.id}>
          {list.children.map(link => {
            const linkTo = link.linkTo
            return <li key={link.id} className={styles.column}>
              <Link element={linkTo.element} type="entry" className={styles.bold}>
                {link.altText || linkTo.element.title}
              </Link>

              {linkTo.element.children && linkTo.element.children.length ?
                <ul className={styles.listSmall}>
                  {linkTo.element.children.map(sublink => <li key={sublink.id}>
                    <Link element={sublink} type="entry" className={styles.plain}>{sublink.title}</Link>
                  </li>)}
                </ul>
                : ""}
            </li>
          })}
        </ul>
      })}
    </div>


  </div>
}

export default Sitemap