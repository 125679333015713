import { useEffect, useState } from "react";

const useIsMobile = (breakpoint) => {
  const [isMobile, setIsMobile] = useState();
  useEffect(() => {
    const onResize = () => {
      const nowIsMobile = window.innerWidth < breakpoint;

      if (nowIsMobile !== isMobile) {
        setIsMobile(nowIsMobile);
      }
    };

    onResize();
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  });
  return isMobile;
};

export default useIsMobile;