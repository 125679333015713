import { idtype, inlineImage } from "querypieces";

export default `
... on pageBuilder_collapsableAccordionMatrix_BlockType {
	${idtype}
	heading
	${inlineImage}
	photoBorderSwitch
	altText
	cta {
		text
		url
		type
		element {
			uri
		}
	}
	children {
	  id
	  ... on pageBuilder_accordionChild_BlockType {
		id
		subheader
		body
		accordionMatrix {
		  ... on accordionMatrix_accordianBlock_BlockType {
			id
			bodyCopy
			cost
			programName
			programLength
			cta {
			  text
			  url
			  type
			  element {
				  uri
			  }
			}
		  }
		}
	  }
	}
}
`