import React from "react";

//content blocks
import hero from "./hero";
import halfPhotoHalfText from "./half-photo-half-text";
import navBlockMatrix from "./nav-block-matrix";
import featurePost from "./feature-post";
import header from "./header";
import fullPagePhotoMatrix from "./full-page-photo-matrix";
import relatedArticles from "./related-articles";
import collapsableAccordionMatrix from "./collapsable-accordion-matrix";
import leadershipTeam from "./leadership-team";
import inlinePhotoMatrix from "./inline-photo-matrix";
import outcomesMatrix from "./outcomes-matrix";
import bodyTextAndContactBlocks from "./body-text-and-contact-blocks";
import parterSiteMap from "./parterSiteMap";
import featureCampaign from "./feature-campaign";
import featureReport from "./feature-report";
/* import-cursor */

const components = {
  hero,
  header,
  halfPhotoHalfText,
  navBlockMatrix,
  featurePost,
  fullPagePhotoMatrix,
  relatedArticles,
  collapsableAccordionMatrix,
  leadershipTeam,
  inlinePhotoMatrix,
  outcomesMatrix,
  bodyTextAndContactBlocks,
  parterSiteMap,
  featureCampaign,
  featureReport,
  /* object-cursor */
};

const Blocks = ({ blocks, entry }) => {
  return blocks.map((block) => {
    const Comp = components[block.typeHandle];
    if (!Comp) {
      console.warn(`Missing block type ${block.__typename}`);
      return null;
    }
    return <Comp key={block.id} {...block} entry={entry} />;
  });
};
export default Blocks;
