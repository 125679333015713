import React from "react";
import "./email-error.scss"

const EmailError = () => {

    return (
        <div className="emailError">
            <p>Error. Please refresh and try again.</p>
        </div>
    );
    

}

export default EmailError