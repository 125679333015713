import { idtype } from "querypieces";

export default `
... on pageBuilder_bodyTextAndContactBlocks_BlockType {
	${idtype}
	simpleRichTextBody
	subheader
	contactBlock {
	  ... on contactBlock_conactBlock_BlockType {
		id
		contactName
		headline
		body
		emailAddress {
		  url
		  text
		  customText
		}
		phoneNumber {
		  url
		  customText
		  text
		}
	  }
	}
  }
`

