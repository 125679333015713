import React from "react";
import "./loading.scss";

const Loading = () => {
    return (
        <div className="loadingPage">
            <h1 className="loadingPageHeader">Loading...</h1>
        </div>
    );
}

export default Loading