import React, { useEffect, useContext, useState } from "react";
import query from "./query";
import SearchResult from "./searchResult";
import fetch from "../../fetch";
import Loading from "components/loading";
import Error from "components/error";
import NotFound from "components/not-found";
import Seo from "components/seo";
import { ReactComponent as SearchIcon } from "../../assets/search_icon.svg";

import { AppContext } from "appContext";

import styles from "./search.module.scss";

const Search = ({ match }) => {

  const [searchKeyWord, setSearchKeyword] = useState(null);
  const [searchVal, setSearchVal] = useState('');
  const [autoFocus, setAutoFocus] = useState(false);

  const uri = match.url.replace("/", "");
  const { data, loading, error } = fetch(query, uri);

  const navigationContext = useContext(AppContext);

  useEffect(() => {
    navigationContext.setHasHero(false);
    navigationContext.setNewsTheme(false);
    navigationContext.setIsReport(false);
  }, [data, navigationContext]);

  if (loading) {
    return <Loading />;
  }

  if (error && !data) {
    return <Error />;
  }

  if (!data.entry) {
    return <NotFound />;
  }

  const SearchForm = ({ placeholder }) => {

    const handleSearchSubmit = (e) => {
      e.preventDefault();
      setSearchKeyword(e.target[0].value);
    };

    const handleSearchChange = (e) => {
      e.preventDefault();
      setSearchVal(e.target.value);
      setAutoFocus(true);
    }

    return (
      <div>
        <form
          action="/search"
          className={styles.searchForm}
          onSubmit={handleSearchSubmit}
        >
          <input
            aria-label="search input"
            className={styles.searchInput}
            type="search"
            placeholder={placeholder}
            value={searchVal}
            autoFocus={autoFocus}
            onChange={handleSearchChange}
          />
          <button className={styles.searchBtn} aria-label="search bar submit button" type="submit">
            <SearchIcon />
          </button>
        </form>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Seo seomaticData={data.seomatic} />
      <div className={styles.searchPageWrapper}>
        <h1>{data.entry.heading ? data.entry.heading : "Search"}</h1>
        <SearchForm
          placeholder={data.entry.plainText ? data.entry.plainText : "Search keywords here"}
        />

        <SearchResult
          searchValue={searchKeyWord}
          noResultsText={data.entry.body ? data.entry.body : "Sorry! No results found, try searching a different keyword."}
          siteMapText={data.entry.introBody}
          siteMapTextNoResults={data.entry.subheader}
          siteMap={data.entry.siteMap}
        />
      </div>
    </React.Fragment>
  );
}

export default Search




