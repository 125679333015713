import { idtype, fullPageImage } from "querypieces";

export default `
... on pageBuilder_fullPagePhotoMatrix_BlockType {
	${idtype}
	id
	themePicker
	fullPagePhotoMatrix {
	  ... on fullPagePhotoMatrix_fullPagePhoto_BlockType {
		id
		altText
		photoBorderSwitch
		${fullPageImage}
	  }
	  ... on fullPagePhotoMatrix_header_BlockType {
		id
		header
	  }
	  ... on fullPagePhotoMatrix_subheader_BlockType {
		id
		subheader
	  }
	  ... on fullPagePhotoMatrix_introBody_BlockType {
		id
		introBody
	  }
	  ... on fullPagePhotoMatrix_body_BlockType {
		id
		body
	  }
	  ... on fullPagePhotoMatrix_cta_BlockType {
		id
		cta {
			text
			type
			url
			element {
			  uri
			}
		}
	  }
	}
  }

`