import React from "react";
import Loading from "components/loading";
import Error from "components/error";
import RichText from "components/richText";
import useIsMobile from "helpers/useIsMobile";
import { AccordionItem } from "blocks/collapsable-accordion-matrix/collapsable-accordion-matrix";
import "./partnerSiteMap.scss";

import locationQuery from "./locationQuery";
import { useQuery } from "@apollo/react-hooks";

import styles from "./parterSiteMap.module.scss";
import Map from "./map";

const ParterSiteMap = ({ heading, richText }) => {

  const { loading, error, data } = useQuery( locationQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: "all"
  });

  const breakpoint = 992;
  const isMobile = useIsMobile(breakpoint);

  if (loading) {
    return <Loading />;
  }

  if (error && !data) {
      return <Error />;
  }

  return (
    <React.Fragment>
      <section className={styles.mapSection}>
        <div> 
          <div className={styles.mapHeader}>{heading}</div>
          <Map markers={data.entries} isMobile={isMobile} />
          {isMobile ? 
            <div className="partnerAccordionBlock"> 
            {data.entries.map( ({id, richText, title }, i) => 
                <AccordionItem 
                  key={id} 
                  bodyCopy={richText}
                  programName={title}
                  firstOpen={i === 0}
                />
            )}
        </div>
          : ''}
          <RichText 
              richText={richText}
              textColor="blue"
              className={styles.mapDescription}
          />
        </div>
      </section>
    </React.Fragment>
  ); 
}

export default ParterSiteMap