import React from "react";
import styles from "./reports.module.scss";
import {ReactComponent as WhiteLogo} from '../../../src/assets/fh_logo_white.svg';
import {ReactComponent as MobileWhiteLogo} from '../../../src/assets/fh_mobile_logo_white.svg';
import { ReactComponent as Carot } from "../../../src/assets/white_down_carrot.svg";
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import useIsMobile from "helpers/useIsMobile";
import * as Scroll from 'react-scroll';

export const ReportNav = ( {children, title} ) => {

    const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(children.length+1);
    const breakpoint = 768;
    const isMobile = useIsMobile(breakpoint);
    const ScrollLink = Scroll.Link;

    return (
        <nav className={styles.specialReportsNav}>
            <div className={styles.reportNavInnerWrapper}>
                <div className={styles.reportLogoContainer}>
                    <a href="/" className="logo" aria-label="Fountain House Logo">
                        {isMobile ? 
                            <MobileWhiteLogo className={styles.reportLogo}/> : 
                            <WhiteLogo className={styles.reportLogo}/> }
                    </a>
                    <h5 className={styles.navTitle}>{title}</h5>
                </div>
                <div>
                    <button {...buttonProps} className={styles.dropDownBtn} aria-expanded={isOpen}>
                        <span>Contents</span>
                        <figure className={`${styles.drownDownCarot} ${isOpen ? styles.open : ''}`}><Carot/></figure>
                    </button>
                    <div className={`${styles.dropDownMenu} ${isOpen && styles.visible}`} role='menu'>
                        <ScrollLink
                            className={styles.menuItem}
                            smooth={true} 
                            duration={500} 
                            to={"top"}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <a {...itemProps[0]}>{title}</a>
                        </ScrollLink>

                        {
                            children.map( ({id, slug, title}, i) => {
                                return (
                                    <ScrollLink 
                                        className={styles.menuItem}
                                        key={id + i} 
                                        smooth={true} 
                                        duration={500} 
                                        to={slug}
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        <a {...itemProps[i+1]}>{title}</a>
                                    </ScrollLink>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </nav>
    );
};
