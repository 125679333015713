import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { relatedArticleCard } from "querypieces";

const filteredNews = gql`
    query($articleType: [QueryArgument], $offset: Int, $limit: Int) {
        entries(section: "Blog", articleType: $articleType, limit: $limit, offset: $offset) {
            ${relatedArticleCard}
        }
        entryCount(section: "Blog", articleType: $articleType)
    }
`;

export default (activeFilter, offset, limit) => {

    const {data, fetchMore} = useQuery(filteredNews, {
        variables: {
            articleType: activeFilter === "All" ? null : activeFilter,
            offset: offset,
            limit: limit
        },
        notifyOnNetworkStatusChange: true
    });
    
    return {articles: data, fetchMore};
}
