import hero from "./hero/query";
import halfPhotoHalfText from "./half-photo-half-text/query";
import navBlockMatrix from "./nav-block-matrix/query";
import featurePost from "./feature-post/query";
import header from "./header/query";
import fullPagePhotoMatrix from "./full-page-photo-matrix/query";
import relatedArticles from "./related-articles/query";
import collapsableAccordionMatrix from "./collapsable-accordion-matrix/query";
import inlinePhotoMatrix from "./inline-photo-matrix/query";
import outcomesMatrix from "./outcomes-matrix/query";
import bodyTextAndContactBlocks from "./body-text-and-contact-blocks/query";
// import leadershipTeam from "./leadership-team/query";
// import parterSiteMap from "./parterSiteMap/query";
// import featureCampaign from "./feature-campaign/query";
// import featureReport from "./feature-report/query";
/* import-cursor */

const blocks = [
  hero,
  header,
  halfPhotoHalfText,
  navBlockMatrix,
  featurePost,
  fullPagePhotoMatrix,
  relatedArticles,
  collapsableAccordionMatrix,
  inlinePhotoMatrix,
  outcomesMatrix,
  bodyTextAndContactBlocks,
  /* object-cursor */
].join("\n");

export default blocks;
