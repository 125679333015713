import React from "react";
import styles from "./half-photo-half-text.module.scss";
import Image from "components/image";
import Button from "components/button";

const HalfPhotoHalfText = ({heading, halfPhotoHalfText}) => {

 return (
    <div className={styles.photoTextTable}>
        <div className={styles.photoTextTableInnerWrapper}>
        {heading ? <h1 className={styles.heading}>{heading}</h1> : null}
            {halfPhotoHalfText.map( ({id, body, halfPageImage, photoBorderSwitch, header, cta}) => {
                return (
                    <div key={id} className={styles.row}>
                        {
                            photoBorderSwitch ? 
                            
                            <figure className={styles.imageBorder}>
                                {
                                    halfPageImage[0].extension === "svg" ? 

                                    <Image extension={halfPageImage[0].extension} title={halfPageImage[0].title} url={halfPageImage[0].url}/>
                                    : 
                                    <Image title={halfPageImage[0].title} {...halfPageImage[0].optimized}/>

                                }
                            </figure>
                            
                            :
                            
                            <figure className={styles.noImageBorder}>
                                {
                                    halfPageImage[0].extension === "svg" ? 

                                    <Image extension={halfPageImage[0].extension} title={halfPageImage[0].title} url={halfPageImage[0].url}/>
                                    : 
                                    <Image {...halfPageImage[0].optimized} title={halfPageImage[0].title}/>

                                }
                            </figure>
                        }
                    
                        <div className={styles.textBlock}> 
                            <h3>{header}</h3> 
                            <p>{body}</p>
                        
                            {(cta.url || cta.element) ? 
                                <div className={styles.cta}>
                                    <Button  
                                        theme="white" 
                                        primary={false}
                                        {...cta}
                                    >
                                    </Button> 
                                </div>
                            : null}
                                
                        
                        </div>
                        
                    </div>
                );
            })}
            
        </div>
    </div>
 );
  
}

export default HalfPhotoHalfText