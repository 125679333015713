import { gql } from "apollo-boost";
import { idtype } from "querypieces";

export default gql`
  query($uri: String) {
    entry(uri: [$uri]) {
      ... on search_search_Entry {
        id
        heading
        plainText
        body
        introBody
        subheader

        siteMap {
          ... on siteMap_linkList_BlockType {
            ${idtype}
            children {
              ... on siteMap_pageLinks_BlockType {
                ${idtype}
                altText
                linkTo {
                  element {
                    ... on pages_page_Entry {
                      level
                      id
                      title
                      uri
                      children {
                        ... on pages_page_Entry {
                          id
                          title
                          uri
                        }
                      }
                    }
                    ... on contact_contact_Entry {
                      level
                      id
                      heading
                      title
                      uri
                    }
                    ... on news_news_Entry {
                      level
                      id
                      heading
                      uri
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    seomatic (uri: $uri) {
        metaTitleContainer
        metaTagContainer
    }
  }
`;
