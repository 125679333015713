import { idtype, halfPageImage } from "querypieces";

export default `
...on pageBuilder_halfPhotoHalfText_BlockType {
	${idtype}
	heading
	halfPhotoHalfText {
		...on halfPhotoHalfText_halfPhotoHalfText_BlockType {
			id
			${halfPageImage}
			photoBorderSwitch
			header
			body
			cta {
				text
				type
				url
				element {
				  uri
				}
			}
		}
	}
}
`
