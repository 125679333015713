import React, { useState } from "react"
import { useLocation } from "react-router-dom";
import RelatedArticleCard from "blocks/related-articles/related-article-card";
import loadArticles from "./loadArticles";

import "./news.scss";

const News = () => {
  const { search } = useLocation();
  let filterQ = search ? decodeURI(search.split('?filter=')[1]) : "All";
  const [activeFilter, setActiveFilter] = useState(filterQ);
  const { articles, fetchMore } = loadArticles(activeFilter, 0, 12);

  const articleTypes = [
    "Student Stories",
    "Press",
    "News",
    "Blog"
  ];

  let link;

  const seeMoreResults = () => {
    fetchMore({
      variables: {
        offset: articles.entries.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return Object.assign({}, prev, {
          entries: [...prev.entries, ...fetchMoreResult.entries],
        });
      },
    });
  }

  const setFilters = (filter) => {
    setActiveFilter(filter);
    const encoded = encodeURI(`?filter=${filter}`);

    if (typeof window !== "undefined") {
      window.history.replaceState(null, null, encoded);
    }
  }

  return (
    <div className="newsWrapper">
      <div className="filterWrapper">
        <p className="filterTitle">{""}</p>
        <ul className="filterTitles">
          <li
            role="button"
            tabIndex="0"
            aria-pressed={'All' === activeFilter ? true : false}
            className={'All' === activeFilter ? `active filter` : `filter`}
            onClick={(e) => {
              setFilters("All");
              e && e.target && e.target.blur();
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setFilters("All");
              }
            }}
          >
            All
          </li>
          {articleTypes.map(articleType =>
            <li
              role="button"
              tabIndex="0"
              aria-pressed={articleType === activeFilter ? true : false}
              key={articleType}
              className={articleType === activeFilter ? `active filter` : `filter`}
              onClick={(e) => {
                setFilters(articleType);
                e && e.target && e.target.blur();
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setFilters(articleType);
                }
              }}
            >
              {articleType}
            </li>
          )}
        </ul>
        {
          (articles && articles.entries.length > 0) ?
            <div className="newsArticlesWrapper">
              {articles.entries.map((elem) => {

                const { id, altText, articleType, relatedArticleImage } = elem;
                const { authors, date, header, publisher } = elem.blogPostHeaderMatrix[0];

                // if related article is linking to external post grab URL
                if (elem.__typename === 'blog_externalPost_Entry') {
                  link = {
                    url: elem.externalUrl.url
                  }
                } else {
                  link = {
                    type: "entry",
                    element: {
                      uri: elem.uri
                    }
                  }
                }

                return (
                  <RelatedArticleCard
                    key={id}
                    link={link}
                    className="newsCard"
                    articleType={articleType}
                    altText={altText}
                    articleImage={relatedArticleImage}
                    header={header}
                    date={date}
                    publisher={publisher}
                    authors={authors}
                    singleCard={false}
                  />
                );
              })}
            </div>
            :
            <p className="noNewsResults">
              Sorry! Nothing to read right now, try viewing a different filter.
            </p>
        }
        {
          (articles &&
            articles.entries &&
            articles.entries.length > 0 &&
            (articles.entries.length < articles.entryCount)) ?
            <button className="seeMoreBtn" onClick={seeMoreResults}> See more </button>
            : null}
      </div>
    </div>

  );
}


export default News


