import { gql } from "apollo-boost";


export default gql`
query($uri:String) {
    retour(uri: $uri) {
      id
      enabled
      redirectSrcUrl
      redirectSrcUrlParsed
      redirectSrcMatch
      redirectMatchType
      redirectDestUrl
      redirectHttpCode
    }
  }
`;
