import React, { useContext, useEffect } from "react"
import "./hero.scss"
import Image from "components/image"; 

import { AppContext } from "appContext";

const Hero = ({heroImage, altText, themePicker}) => {

	const themeContext = useContext(AppContext);

	useEffect( () => {
            
        themeContext.setHeroTheme(themePicker);
        
    }, [themePicker, themeContext]);


	return (
		<div className={`heroImage border-${themePicker}`} id='heroImg'>
			<div className="onionSkin"></div>
			<Image {...heroImage[0].optimized} title={heroImage[0].title} alt={altText}/>
		</div>
	); 
}

export default Hero