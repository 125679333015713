import { idtype, inlineImage } from "querypieces";

export default `
... on pageBuilder_inlinePhotoMatrix_BlockType {
	${idtype}
	inlinePhotoMatrix {
	  ... on inlinePhotoMatrix_inlinePhoto_BlockType {
		id
		altText
		photoBorderSwitch
		${inlineImage}
	  }
	  ... on inlinePhotoMatrix_bigHeader_BlockType {
		id
		header
	  }
	  ... on inlinePhotoMatrix_bodyCopy_BlockType {
		id
		body
	  }
	  ... on inlinePhotoMatrix_subheader_BlockType {
		id
		subheader
	  }
	  ... on inlinePhotoMatrix_cta_BlockType {
		id
		cta {
			title
			text
			type 
			url 
			element {
				uri
			}
		}
	  }
	  ... on inlinePhotoMatrix_contactDownloadCta_BlockType {
		id
		phoneNumber {
			url
			customText
			text
		}
		email {
			url
			text
			customText
		}
		location
		downloadCta {
			url
			text
			type
		}
	  }
	}
  }
`
