import React from "react";
import "./menu-icon.scss";

const MenuIcon = ({ active }) => {
  return (
    <div className={`menuIcon ${active ? "active" : ""}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
export default MenuIcon;