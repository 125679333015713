import React from "react"
import fetchRelatedArticles from "../../fetchRelatedArticles";
import tagsQuery from "./tagsQuery";
import RelatedArticleCard from "./related-article-card";
import Loading from "components/loading";
import Error from "components/error";

import styles from "./related-articles.module.scss"

const RelatedArticles = ({ subheader, body, tags, limit }) => {
  let link;
  const tagIds = tags.map((elem) => elem.id);

  // if supplied with hard limit of articles use that, otherwise limit to 9 articles
  const articleLimit = limit ? limit : 9;

  const { data, loading, error } = fetchRelatedArticles(tagsQuery, tagIds, articleLimit);

  if (loading) {
    return <Loading />;
  }

  if (error && !data) {
    return <Error />;
  }

  return (
    <div className={styles.articlesWrapper}>
      <div className={styles.articlesInnerWrapper} >

        {(subheader || body) &&
          <div className={styles.articlesHeader}>
            {subheader ? <h3>{subheader}</h3> : null}
            {body ? <p>{body}</p> : null}
          </div>
        }

        <div className={styles.cardWrapper}>
          {data.entries.map((elem) => {

            const { authors, date, header, publisher } = elem.blogPostHeaderMatrix[0];
            const { altText, relatedArticleImage, articleType, id } = elem;

            // if related article is linking to external post grab URL
            if (elem.__typename === 'blog_externalPost_Entry') {
              link = {
                url: elem.externalUrl.url
              }
            } else {
              link = {
                type: "entry",
                element: {
                  uri: elem.uri
                }
              }
            }

            return (
              <RelatedArticleCard
                key={id}
                link={link}
                articleType={articleType}
                altText={altText}
                articleImage={relatedArticleImage}
                header={header}
                date={date}
                publisher={publisher}
                authors={authors}
                singleCard={false}
              />
            );
          })}
        </div>

      </div>
    </div>
  );
}

export default RelatedArticles