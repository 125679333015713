import React from "react"
import Image from "components/image";
import Button from "components/button";
import RichText from "components/richText";
import ContactBlock from "blocks/contact-block.js";

import styles from "./inline-photo-matrix.module.scss"

const InlinePhotoMatrix = ({inlinePhotoMatrix}) => {

    return (

        <div className={styles.inlineMatrixWrapper}> 
            {inlinePhotoMatrix.map ( (elem) => {
                    
                    if (elem.__typename === "inlinePhotoMatrix_bigHeader_BlockType") {
                        return (
                            <h1 key={elem.id} className={styles.inlineHeader}>{elem.header}</h1>
                        );
                    } else if (elem.__typename === "inlinePhotoMatrix_inlinePhoto_BlockType") {
                        
                        if (elem.photoBorderSwitch) {    
                            return (
                                <figure key={elem.id} className={styles.imageBorder}>
                                { 
                                    elem.inlineImage[0].extension === "svg" ?
                                        <Image extension={elem.inlineImage[0].extension} url={elem.inlineImage[0].url} alt={elem.altText} title={elem.inlineImage[0].title} />
                                      : <Image {...elem.inlineImage[0].optimized} alt={elem.altText} title={elem.inlineImage[0].title}/>
                                }
                                </figure>
                            );
                        } else {
                            return (
                                <figure key={elem.id} className={styles.noImageBorder}>
                                { 
                                    elem.inlineImage[0].extension === "svg" ?
                                        <Image extension={elem.inlineImage[0].extension} url={elem.inlineImage[0].url} alt={elem.altText} title={elem.inlineImage[0].title}/>
                                      : <Image {...elem.inlineImage[0].optimized} alt={elem.altText} title={elem.inlineImage[0].title}/>
                                }
                                </figure>
                            );
                        }
                    } else if (elem.__typename === "inlinePhotoMatrix_subheader_BlockType"){
                        return (
                            <h3 key={elem.id} className={styles.inlineSubheader}>{elem.subheader}</h3>
                        );
                    
                    } else if (elem.__typename === "inlinePhotoMatrix_bodyCopy_BlockType"){
                        return (
                            <div key={elem.id} className={styles.inlineBody}>
                                <RichText 
                                    richText={elem.body}
                                    textColor="blue"
                                />
                            </div>
                        );

                    } else if (elem.__typename === "inlinePhotoMatrix_cta_BlockType"){
                        return (
                            <div key={elem.id} className={styles.inlineCta}>
                                <Button 
                                    primary={true}
                                    theme="blue"
                                    {...elem.cta}
                                ></Button>
                            </div>
                        );
                    } else if (elem.__typename === "inlinePhotoMatrix_contactDownloadCta_BlockType") {
                        
                        return (
                            <ContactBlock
                                key={elem.id}
                                emailAddress={elem.email}
                                contactName={elem.location}
                                phoneNumber={elem.phoneNumber}
                                downloadCta={elem.downloadCta}
                            >

                            </ContactBlock>
                        );
                    }
                    return (null);
                })}
        </div>
    );
}

export default InlinePhotoMatrix