import React from "react"
import Image from "components/image";
import RelatedArticleCard from "blocks/related-articles/related-article-card";
import Link from "components/link";
import { ReactComponent as Arrow } from "../../assets/fh_arrow.svg";
import useIsMobile from "helpers/useIsMobile";

import "blocks/theme-picker.scss";
import styles from "./feature-report.module.scss"


const FeatureReport = ({ id, themePicker, altText, plainText, featurePostImage, linkTo }) => {
  const breakpoint = 992;
  const isMobile = useIsMobile(breakpoint);

  const articleType = "Report"

  const { heroHeading, introBody, heroImage } = linkTo.element
  const image = featurePostImage.length ? featurePostImage : heroImage

  return (
    isMobile ?
      <div className={`${themePicker} ${styles.mobileFeatureContainer}`}>
        <RelatedArticleCard
          link={linkTo}
          id={id}
          articleType={articleType}
          altText={altText}
          articleImage={image}
          header={heroHeading}
          singleCard={true}
        />
      </div>

      :
      <div className={`${styles.featureContainer}`}>
        <Link className={`border-${themePicker} ${styles.featureLink}`} {...linkTo} >
          <>
            <Image alt={altText} title={image[0].title} {...image[0].optimized} />
            <div className={styles.blogContent}>
              <p className={styles.typeHandle}>{articleType}</p>
              <h1 className={styles.headline}>{heroHeading}</h1>
              <p className={styles.summary}>{plainText || introBody}</p>
              <figure className={styles.featurePostArrow}><Arrow /></figure>
            </div>
          </>
        </Link>
      </div>
  );
}

export default FeatureReport