import { idtype } from "querypieces";

export default `
...on pageBuilder_header_BlockType {
	${idtype}
	breadcrumb
	heading
	introBody
	richText
	cta {
		title
		text
		type 
		url 
		element {
			uri
		}
	}
}
`