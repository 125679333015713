import lazySizes from "lazysizes";
import React from "react";
import { render, hydrate } from "react-dom";
import App from "components/app";
import { BrowserRouter } from "react-router-dom";
import ScrollMemory from 'react-router-scroll-memory';
import { AppProvider } from "appContext";
import { ApolloProvider } from "react-apollo";
import createClient from "./create-apollo-client";

import "./index.css";

const renderMethod = !!module.hot ? render : hydrate;
const client = createClient();

lazySizes.init();

renderMethod(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <AppProvider>
      <ScrollMemory />
        <App/>
      </AppProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
