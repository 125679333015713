import React, { useState, createContext } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {

  const [hasHero, setHasHero] = useState(true);
  const [newsTheme, setNewsTheme] = useState(false);
  const [heroTheme, setHeroTheme] = useState('home');
  const [isReport, setIsReport] = useState(false);

  return (
    <AppContext.Provider
      value={{
        setHasHero,
        hasHero,
        setNewsTheme,
        newsTheme,
        setHeroTheme,
        heroTheme,
        setIsReport,
        isReport,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};