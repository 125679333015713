import { gql } from "apollo-boost";
import { linkTo, link } from "querypieces";


export default gql`
{
    globalSet(handle:"footer") {
        ... on footer_GlobalSet {
            id
            name
            cityStateZipcode
            officeName
            streetAddress
            footerLinks {
              ... on footerLinks_linkBlocks_BlockType {
                id
                ${linkTo}
              }
            }
            logoImageBlock {
              ... on logoImageBlock_footerLogos_BlockType {
                id
                altText
                logo {
                  ... on images_Asset {
                    id
                    img
                    url
                  }
                }
                externalLink {
                  ${link}
                }
              }
            }
            socialMediaUrls {
                ... on socialMediaUrls_socialMediaUrls_BlockType {
                  id
                  facebook {
                    url
                  }
                  instagram {
                    url
                  }
                  twitter {
                    url
                  }
                  youtube {
                    url
                  }
                }
              }
        }  
    }
}
`;
