import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { useLocation } from "react-router-dom";
import query from "./query.js";
import Link from "components/link";

import styles from "./alertBar.module.scss";

const AlertBar = () => {
  const uri = useLocation().pathname;

  const { loading, error, data } = useQuery(query);

  if (loading || error) {
    return null;
  }

  const {plainText, cta} = data.alertBar;
  const hasData = !!(plainText || ((cta && cta.element) || (cta && cta.url)));

 return (
  hasData && uri === '/' &&
  <div className={styles.alertBar}>
    <div className={styles.alertBarText}>{plainText}</div>
    <Link className={styles.alertBarLink} {...cta}/>
  </div>
    
  );
}

export default AlertBar