import React, { useContext, useEffect } from "react";
import Blocks from "blocks";
import usePage from "./usePage";
import Loading from "components/loading";
import Error from "components/error";
import NotFound from "components/not-found";
import News from "components/news";

import { AppContext } from "appContext";
import Seo from "components/seo";

const Page = ({ match }) => {
  const { loading, error, data } = usePage({
    uri: match.url.replace("/", "")
  });

  const navigationContext = useContext(AppContext);
  useEffect(() => {
    let hasHero = false;
    if (data && data.entry) {
      data.entry.pageBuilder.forEach(element => {
        if (element.typeHandle === 'hero') {
          hasHero = true;
        }
      });
    }

    navigationContext.setHasHero(hasHero);
    navigationContext.setNewsTheme(false);
    navigationContext.setIsReport(false);
  }, [data, navigationContext]);

  if (loading) {
    return <Loading />;
  }

  if (error && !data) {
    return <Error />;
  }

  if (!data.entry) {
    return <NotFound />;
  }

  switch (data.entry.typeHandle) {
    case "page":
      return (
        <React.Fragment>
          <Seo seomaticData={data.seomatic} />
          {data.entry.slug === "blog" ? <News /> : ""}
          <Blocks blocks={data.entry.pageBuilder} />
        </React.Fragment>
      );
    default:
      return null;
  }
};

export default Page;
